<template>
	<div>
		<v-card
			rounded="xl"
			class="ma-4"
		>
			<v-card-title>
				This is article sidebar
			</v-card-title>
		</v-card>
		<v-divider />
	</div>
</template>

<script>
export default {
	name: "Sidebar",
	props: {},
	data: () => ({}),
	computed: {},
	methods: {}
}
</script>

<style scoped>

</style>
